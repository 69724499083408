const headCells = [{
  id: 'specie',
  label: 'Specie',
  name: 'Specie',
  numeric: false
}, {
  id: 'soi',
  label: 'Soiul',
  name: 'Soiul',
  numeric: false
}, {
  id: 'categoriaBiologica',
  label: 'Categorie Biologica',
  name: 'Categorie Biologica',
  numeric: false
}, {
  id: 'lot',
  label: 'Lot',
  name: 'Nr. de referinta al lotului',
  numeric: false
},
{
  id: 'cantitateaLivrata',
  label: 'Cantitate',
  name: 'Cantitatea livrata (kg)',
  numeric: false
}, {
  id: 'nrAmbalajeTip',
  label: 'Ambalaje',
  name: 'Numar de ambalaje si tip',
  numeric: false
}, {
  id: 'documentCertificare',
  label: 'Document certificare',
  name: 'Document oficial de certificare a semintei nr. ',
  numeric: false
}, {
  id: 'emitent',
  label: 'Emitent',
  name: 'Emitent',
  numeric: false
},
{
  id: 'data',
  label: 'Data',
  name: 'Data',
  numeric: false
},
{
  id: 'eticheta',
  label: 'Etichete',
  name: 'Etichete oficiale seria si nr., de la   pana la',
  numeric: false
},
{
  id: 'puritate',
  label: 'Puritate fizica',
  name: 'Puritate fizica %',
  numeric: false
},
{
  id: 'seminteStraine',
  label: 'Seminte straine',
  name: 'Seminte straine nr.',
  numeric: false
},
{
  id: 'umiditate',
  label: 'Umiditate',
  name: 'Umiditate %',
  numeric: false
},
{
  id: 'germinatie',
  label: 'Germinatie',
  name: 'Germinatia totala %',
  numeric: false
},
{
  id: 'masaBoabe',
  label: 'Masa 1000 boabe',
  name: 'Masa a 1000 boabe',
  numeric: false
},
{
  id: 'stareSanitara',
  label: 'Stare sanitara',
  name: 'Stare sanitara',
  numeric: false
},
{
  id: 'coldTest',
  label: 'Cold test',
  name: 'Cold test',
  numeric: false
},
{
  id: 'producator',
  label: 'Producator',
  name: 'Producator',
  numeric: false
},
{
  id: 'tara',
  label: 'Tara origine',
  name: 'Tara de origine',
  numeric: false
}
];

export default headCells;