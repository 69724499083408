import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link, Redirect} from 'react-router-dom';
import LinkMaterial from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {green} from '@material-ui/core/colors';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <LinkMaterial color="inherit" href="https://miculaprozar.ro">
      Micul Aprozar
    </LinkMaterial>{' '}
    office@miculaprozar.ro{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: green[600],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);
  const [failedLogin, setFailedLogin] = React.useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/user`, {
    headers: {'Access-Control-Allow-Origin': '*'}})
        .then(res => {
            setRedirect(true);
        })
        .catch(err => {
        })
}, []);

    const handlerLogIn = () => {
        let data = {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value
        }
        axios.post(`${process.env.REACT_APP_URL}/user/logIn`, data,{
            headers: {'Access-Control-Allow-Origin': '*'}})
                .then(res => {
                    window.localStorage.setItem('token',res.data);
                    setRedirect(true);
                })
                .catch(err => {
                  setFailedLogin(true);
                })
    }

    const handleKeypress = e => {
      //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handlerLogIn()
    }
  }

  return (
    <Container component="main" maxWidth="xs">
    {redirect ? <Redirect from="/logIn" to="/acasa"></Redirect> : null}
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Autentificare
        </Typography>
        <Typography component="p" variant="subtitle1" style={{marginTop: 3}}>
          Folosește contul tău pentru a intra în aplicație.
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresă Email"
            name="email"
            autoComplete="email"
            autoFocus
            onClick = {() => {setFailedLogin(false)}}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Parolă"
            type="password"
            id="password"
            onKeyUp={handleKeypress}
            onClick = {() => {setFailedLogin(false)}}
            autoComplete="current-password"
          />
          {
            failedLogin ? <div style={{fontSize: 18, color: 'red'}}>User sau parola gresita!</div> : null
          }
          <Button
            fullWidth
            variant="contained"
            style={{backgroundColor: green[700], color: "white", boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)"}}
            className={classes.submit}
            onClick={handlerLogIn}
          >
            Intră în cont
          </Button>
          <Grid container>
           {/*  <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid> */}
            <Grid item>
              <Link to="/signUp" variant="body2">
                {"Nu ai cont? Creează"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}