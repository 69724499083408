import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Laptop';
import {Link} from 'react-router-dom';
import './assets/css/sidebar.css'

export const mainListItems = (
  <div>
    <Link className="sideBarLink" to="/acasa" style={{textDecoration: 'none'}}>
    <ListItem className="sideBarOption">
      <ListItemIcon>
        <DashboardIcon style={{color: window.location.href.includes('acasa') ? "#2e7d32" : ""}} />
      </ListItemIcon>
      <ListItemText primary="Panou principal" style={{color: window.location.href.includes('acasa') ? "#2e7d32" : "black"}} />
    </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
  </div>
);