import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Link, Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { mainListItems } from './listItems';
import EnhancedTable from './components/Table';
import headCells from './constants/headCells';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { red, green, lightGreen } from '@material-ui/core/colors';
import image from './assets/sigla_alb.png';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    paddingTop: 10,
    paddingBottom: 10,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Dashboard() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(false);
  const [accountError, setAccountError] = React.useState(``);
  const [accountLimitError, setAccountLimitError] = React.useState(``);
  const matches = useMediaQuery('(max-width:485px)');
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const addProductInList = (product) => {
    let newRows = [];
    newRows.push(product);
    let concatNewRos = newRows.concat(rows);
    setRows(concatNewRos);
  }

  const updateProductInList = (product) => {
    //let newRows = rows;
    let newRowsModified = [...rows].map(row => {
      if (row.id === product.id) {
        return product;
      }
      return row;
    })
    setRows(newRowsModified)
  }

  const updateProductAfterDelete = (id) => {
    let newRowsModified = [...rows].filter(row => {
      if (row.id !== id) {
        return row;
      }
    })
    setRows(newRowsModified)
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL}/user`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': window.localStorage.getItem('token')
      }
    })
      .then(res => {
        setCurrentUser(res.data[0]);
      })
      .catch(err => {
      });

    axios.get(`${process.env.REACT_APP_URL}`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': window.localStorage.getItem('token')
      }
    })
      .then(res => {
        setRows(res.data);
      })
      .catch(err => {
        if (err && err.response && err.response.status === 403) {
          setAccountError(err.response.data.message)
        }
        if (err && err.response && err.response.status === 400) {
          setAccountLimitError(err.response.data.message)
        }
        if (err && err.response && err.response.status === 401 && window.location.href.includes('/acasa')) {
          setRedirect(true);
        }
      })
  }, []);

  const handlerLogOut = () => {
    window.localStorage.setItem('token', '');
    setRedirect(true)
  }



  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      {redirect ? <Redirect from="/acasa" to="/pachete"></Redirect> : null}
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)} style={{ backgroundColor: green[600] }}>
        <Toolbar className={classes.toolbar}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            >
              <MenuIcon />
            </IconButton>
            {
              matches ? null : <img src={image} style={{ maxWidth: 240, maxHeight: 60 }}></img>
            }
            {
              matches ? <Button
                style={{
                  backgroundColor: "white", color: green[600],
                  boxShadow: '0 1px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%)',
                  maxHeight: '80px'
                }}
                size="small"
                className={classes.button}
                onClick={handlerLogOut}
              >
                <ExitToAppIcon></ExitToAppIcon>
              </Button> : <Button
                style={{
                  backgroundColor: "white", color: green[600],
                  boxShadow: '0 1px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%)',
                  maxHeight: '80px'
                }}
                size="small"
                className={classes.button}
                onClick={handlerLogOut}
                endIcon={<ExitToAppIcon></ExitToAppIcon>}
              >
                  Ieși din cont
                    </Button>
            }
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          {
            <Alert style={{ marginTop: 10, marginBottom: 10, fontSize: 14, backgroundColor: green[600] }}>Cont de tip {currentUser.account}{currentUser.numberOfProducts > 0 ? ` limitat la ${currentUser.numberOfProducts} produse` : ``}. {currentUser.freeDays > 0 ? `Expiră la data de: ${currentUser.expiration.split('T')[0]}` : ``}</Alert>
          }
          {
            currentUser.accountTypeId === 1 && !accountError && !accountLimitError ? <Alert severity="warning" style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}>Pentru acest tip de cont certificatul nu va conține automat datele companiei</Alert> : null
          }
          {
            accountError || accountLimitError ? <Alert severity="error" style={{ marginTop: 10, marginBottom: 10, fontSize: 14 }}>{accountError ? accountError : accountLimitError}</Alert> : null
          }
          {
            !accountError ? <Grid container spacing={3}>
              {/* Chart */}

              {/* Recent Deposits */}

              {/* Recent Orders */}
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <EnhancedTable addProductInList={addProductInList} headCells={headCells} currentUser={currentUser} updateProductInList={updateProductInList} updateProductAfterDelete={updateProductAfterDelete} rows={rows}></EnhancedTable>
                </Paper>
              </Grid>
            </Grid> : null
          }
          {/* <Box pt={4}>
            <Copyright />
          </Box> */}
        </Container>
      </main>
    </div>
  );
}