import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import LinkMaterial from '@material-ui/core/Link';
import {Link, Redirect} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import {green, blue} from '@material-ui/core/colors';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import TermsLayout from './TermsLayout';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <LinkMaterial color="inherit" href="https://miculaprozar.ro">
      Micul Aprozar
    </LinkMaterial>{' '}
    office@miculaprozar.ro{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Basic',
    price: '149',
    description: ['<500 produse', 'Support tehnic', 'Certificat completat automat cu datele companiei']
  },
  {
    title: 'Pro',
    price: '299',
    description: ['<1000 produse', 'Support tehnic', 'Certificat completat automat cu datele companiei']
  },
  {
    title: 'Enterprise',
    price: '499',
    description: ['<3000 produse', 'Support tehnic', 'Certificat completat automat cu datele companiei']
  },
];

export default function Pricing() {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);
  
  useEffect(() => {
    let isCancelled = false;
    axios.get(`${process.env.REACT_APP_URL}/user`, {
    headers: {'Access-Control-Allow-Origin': '*',
    'Authorization': window.localStorage.getItem('token')}})
        .then(res => {
            setRedirect(true);
        })
        .catch(err => {
        })
}, []);

  return (
    <React.Fragment>
      <TermsLayout></TermsLayout>
      {/* End footer */}
    </React.Fragment>
  );
}