import React, {useRef} from 'react';
import { lighten, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Icon from '@material-ui/icons/Add';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DownloadIcon from '@material-ui/icons/GetApp';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Cancel';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import EditIcon from '@material-ui/icons/Edit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { red, green, blue } from '@material-ui/core/colors';
import AccountIcon from '@material-ui/icons/AccountCircle';
import { useReactToPrint } from 'react-to-print';
import '../assets/css/table.css'


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" style={{ border: '1px solid #e2e2e2' }}>
                    <Checkbox
                        style={rowCount > 0 && numSelected === rowCount ? { color: green[600] } : {}}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Selecteaza toate produsele' }}
                    />
                </TableCell>
                {props.headCells.map((headCell, index) => (
                    <Tooltip title={headCell.name} key={index}>
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            style={headCell.id === 'data' ? { padding: 2, paddingRight: 20, fontSize: 11, border: '1px solid #e2e2e2' } : { padding: 2, fontSize: 11, border: '1px solid #e2e2e2' }}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                                className={headCell.label.length > 18 ? classes.mediumCell : classes.smallCell}
                            >
                                <b>{headCell.label}</b>
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    </Tooltip>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 0,
        paddingRight: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 14, 3),
        width: '70%',
        overflowY: 'scroll',
        height: '80%'
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: green[600],
                backgroundColor: "#fbfbf0",
            }
            : {
                color: green[600],
                backgroundColor: "#fbfbf0",
            },
    title: {
        flex: '1 1 100%',
    },
    margin: {
        margin: theme.spacing(2),
    },
}));

const EnhancedTableToolbar = props => {
    const classes = useToolbarStyles();
    const { numSelected } = props;
    const [modal, setModal] = React.useState(false);
    const [modalYouSure, setModalYouSure] = React.useState(false);
    const [successInsert, setSuccessInsert] = React.useState(false);
    const [failedInsert, setFailedInsert] = React.useState(false);
    const [failedInsertWithMessage, setFailedInsertWithMessage] = React.useState('');
    const [selectedProductForUpdate, setSelectedProductForUpdate] = React.useState(null);
    const matches = useMediaQuery('(max-width:485px)');
    const changeModal = (event) => {
        setSelectedProductForUpdate(null);
        setModal(!modal);
    }
    const handleClose = () => {
        setModal(false);
    }
    const handleCloseYouSure = () => {
        setModalYouSure(false);
    }

    const addProduct = () => {
        let product = {};
        props.headCells.map(cell => {
            if (cell.id !== 'data') {
                product[cell.id] = document.getElementById(`${cell.id}_input`).value !== "" ? document.getElementById(`${cell.id}_input`).value : null;
            }
        })
        if (selectedProductForUpdate) {
            axios.put(`${process.env.REACT_APP_URL}/${selectedProductForUpdate.id}`, product, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': window.localStorage.getItem('token')
                }
            })
                .then(res => {
                    setSuccessInsert(true);
                    props.updateProductInList(res.data);
                })
                .catch(err => {
                    if (err && err.response && err.response.status === 403) {
                        setFailedInsertWithMessage(err.response.data.message);
                    }
                    if (err.response && err.response.status === 400) {
                        setFailedInsertWithMessage(err.response.data.message);
                    }
                    else {
                        setFailedInsert(true);
                    }
                })
        }
        else {
            axios.post(`${process.env.REACT_APP_URL}`, product, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': window.localStorage.getItem('token')
                }
            })
                .then(res => {
                    setSuccessInsert(true);
                    props.addProductInList(res.data);
                })
                .catch(err => {
                    if (err && err.response && err.response.status === 403) {
                        setFailedInsertWithMessage(err.response.data.message);
                    }
                    if (err && err.response && err.response.status === 400) {
                        setFailedInsertWithMessage(err.response.data.message);
                    }
                    else {
                        setFailedInsert(true);
                    }
                })
        }
    }

    const updateProduct = () => {
        setSelectedProductForUpdate(props.rows[0]);
        setModal(true)
    }

    const deleteProduct = () => {
        axios.delete(`${process.env.REACT_APP_URL}/${props.rows[0].id}`, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': window.localStorage.getItem('token')
            }
        })
            .then(res => {
                props.updateProductAfterDelete(props.rows[0].id);
                props.deleteSelected();
                handleCloseYouSure(false);
            })
    }

    const exportWord = (type) => {
        if (!window.Blob) {
            alert('Your legacy browser does not support this action.');
            return;
        }

        var html, link, blob, url, css;

        // EU A4 use: size: 841.95pt 595.35pt;
        // US Letter use: size:11.0in 8.5in;

        css = (
            '<style>' +
            '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
            'div.WordSection1 {page: WordSection1;}' +
            'table{border-collapse:collapse; width: 100%}td{border:1px gray solid;width:5em;padding:3px;}' +
            '</style>'
        );

        let rowsHtmlAdiacentObject = {};
        document.getElementById("tableHere").innerHTML = '';
        let count = -1;
        props.rows.forEach((row, index) => {
            if (index % 5 === 0 || index === 0) {
                count++;
                let table = document.createElement("table");
                table.className = "table table-stripped";
                table.id = `table${count}`;
                table.style.borderCollapse = `collapse`;
                table.style.width = `100%`;
                props.headCells.forEach((cell, index) => {
                    if (cell.id !== 'data') {
                        let row = document.createElement("tr");
                        row.id = `row${count}${cell.id}`;
                        let firstCell = document.createElement("td");
                        firstCell.style.width = '160px';
                        firstCell.style.fontSize = "12px";
                        firstCell.style.border = "1px gray solid";
                        firstCell.style.width = "5em";
                        firstCell.style.padding = "3px";
                        firstCell.innerHTML = cell.name;

                        row.appendChild(firstCell);
                        table.appendChild(row);
                    }
                })
                document.getElementById("tableHere").appendChild(table);
                if (index === 0) {
                    for (let i = 0; i < 20; i++) {
                        let br = document.createElement('br');
                        br.id = `br${count}${i}`;
                        document.getElementById("tableHere").appendChild(br);
                    }
                }
                else {
                    for (let i = 0; i < 10; i++) {
                        let br = document.createElement('br');
                        br.id = `br${count}${i}`;
                        document.getElementById("tableHere").appendChild(br);
                    }
                }
            }
            Object.keys(row).forEach((element, index) => {
                if (element !== 'id' && element !== 'data') {
                    let td = document.createElement("td");
                    td.style.fontSize = "12px";
                    td.style.border = "1px gray solid";
                    td.style.width = "5em";
                    td.style.padding = "3px";
                    td.innerHTML = row[element];
                    document.getElementById(`row${count}${element}`).appendChild(td);
                    //rowsHTML.find(rowHtml => rowHtml.id === element).appendChild(td)
                    //rowsHTML[index - 1].appendChild(td);
                }
            })
        })

        if (count === 0) {
            for (let i = 0; i < 20; i++) {
                document.getElementById(`br${count}${i}`).remove();
            }
        }
        else {
            for (let i = 0; i < 10; i++) {
                document.getElementById(`br${count}${i}`).remove();
            }
        }


        /*   let table = document.createElement("table");
          table.className = "table table-stripped";
  
          rowsHTML.forEach(row => {
              table.appendChild(row);
          })
  
          document.getElementById("tableHere").innerHTML = '';
          document.getElementById("tableHere").appendChild(table); */

        html = window.tableHere.innerHTML;
        let templateheader = window.templatePDFheader.innerHTML;
        let templatefooter = window.templatePDFfooter.innerHTML;

        document.getElementById('tableHere').style.display = "inline";
        document.getElementById('templatePDFheader').style.display = "inline";
        document.getElementById('templatePDFfooter').style.display = "inline";
        if(type === "pdf"){
            props.handlePrint()
        }
        else{
            blob = new Blob(['\ufeff', `<!DOCTYPE html> <html><div style="padding: 40">` + templateheader + html + templatefooter + `</div></html>`], {
                type: `application/${type === "doc" ? "msword" : "pdf"}`
            });
            url = URL.createObjectURL(blob);
            link = document.createElement('A');
            link.href = url;
            // Set default file name. 
            // Word will append file extension - do not add an extension here.
            link.download = 'CertificatCalitate_' + new Date().toISOString().split('T')[0];
            document.body.appendChild(link);
            if (navigator.msSaveOrOpenBlob) navigator.msSaveOrOpenBlob(blob, `Document.${type}`); // IE10-11
            else link.click();  // other browsers
            document.body.removeChild(link);
        }
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <>
                    {
                        !matches ? <Typography className={classes.title} color="inherit" variant="subtitle1">
                            {numSelected} selectate
                </Typography> : null
                    }
                </>
            ) : (
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: green[600], color: "white",
                            boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)'
                        }}
                        size="small"
                        className={classes.button}
                        onClick={changeModal}
                        endIcon={<Icon></Icon>}
                    >
                        Adaugă produse
                    </Button>
                )}
            {
                numSelected === 1 ? [
                    <Tooltip title="Modificare Produs">
                        <Button
                            variant="contained"
                            style={{ marginRight: "25px", backgroundColor: green[600], color: "white" }}
                            onClick={updateProduct}
                            size="small"
                            className={classes.margin}
                        >
                            <EditIcon />
                             Modifică
                        </Button>
                    </Tooltip>, <Tooltip title="Stergere Produs">
                        <Button
                            variant="contained"
                            style={{ marginRight: "25px", backgroundColor: red[600], color: "white" }}
                            onClick={() => setModalYouSure(true)}
                            size="small"
                            className={classes.margin}
                        >
                            <DeleteIcon />
                             Șterge
                        </Button>
                    </Tooltip>
                ] : null
            }
            {numSelected > 0 ? [
                <Tooltip title="Download">
                    <Button
                        variant="contained"
                        style={{ marginRight: "5px", backgroundColor: blue[600], color: "white" }}
                        onClick={() => { exportWord('doc') }}
                        size="small"
                        className={classes.margin}
                    >
                        <DescriptionIcon />
                            WORD
                        </Button>
                </Tooltip>,
                <Tooltip title="PrintPDF">
                    <Button
                        variant="contained"
                        style={{ marginRight: "2px", backgroundColor: red[600], color: "white"}}
                        onClick={() => { exportWord('pdf') }}
                        size="small"
                        className={classes.margin}
                    >
                        <PictureAsPdfIcon />
                            PDF
                        </Button>
                </Tooltip>
             ] : null}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modalYouSure}
                onClose={handleCloseYouSure}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modalYouSure}>
                    <div style={{ backgroundColor: "white", padding: 5 }}>
                        <h3 id="transition-modal-title">Esti sigur ca vrei sa stergi {props.rows[0] ? props.rows[0].specie ? props.rows[0].specie : "" : ""}-{props.rows[0] ? props.rows[0].soi ? props.rows[0].soi : "" : ""}?</h3>
                        <br></br>
                        <Button
                            variant="contained"
                            style={{ marginRight: "25px", backgroundColor: red[600], color: "white" }}
                            onClick={deleteProduct}
                            size="small"
                            className={classes.margin}
                        >
                            Da, șterge
                        </Button>
                        <Button
                            variant="contained"
                            style={{ marginRight: "25px", backgroundColor: green[600], color: "white" }}
                            onClick={handleCloseYouSure}
                            size="small"
                            className={classes.margin}
                        >
                            Nu, înapoi
                        </Button>
                    </div>
                </Fade>
            </Modal>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={modal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={modal}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{selectedProductForUpdate ? `Modificare produs` : `Introducere produs`}</h2>
                        <br></br>
                        <Grid container spacing={3}>
                            {
                                props.headCells.map((cell, index) => {
                                    if (cell.id !== 'data') {
                                        return (
                                            <Grid item xl={3} lg={4} md={6} xs={12}>
                                                <FormControl fullWidth className={classes.margin} key={index}>
                                                    <InputLabel htmlFor="input-with-icon-adornment">{cell.label}</InputLabel>
                                                    <Input id={cell.id + "_input"} defaultValue={selectedProductForUpdate ? selectedProductForUpdate[cell.id] : null} onClick={() => { setSuccessInsert(false); setFailedInsert(false); setFailedInsertWithMessage('') }} />
                                                </FormControl>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                        </Grid>
                        <br></br>
                        {
                            successInsert ? <div style={{ fontSize: 18, color: "green", marginLeft: 15 }}>{selectedProductForUpdate ? `Produs modificat` : `Produs adaugat!`}</div> : null
                        }
                        {
                            failedInsert ? <div style={{ fontSize: 18, color: "red", marginLeft: 15 }}>{selectedProductForUpdate ? `Produsul nu a putut fi modificat!` : `Produsul nu a putut fi adaugat!`}</div> : null
                        }
                        {
                            failedInsertWithMessage !== '' ? <div style={{ fontSize: 18, color: "red", marginLeft: 15 }}>{failedInsertWithMessage}</div> : null
                        }
                        <Button
                            variant="contained"
                            style={{ backgroundColor: green[600], color: "white" }}
                            onClick={addProduct}
                            className={classes.margin}
                        >
                            {selectedProductForUpdate ? `Modifică produsul` : `Adaugă produsul`}
                        </Button>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: red[600], color: "white" }}
                            onClick={handleClose}
                            className={classes.margin}
                        >
                            {`Ieșire`}
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mediumCell: {
        width: 'auto'
    },
    smallCell: {
        width: 'auto'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function EnhancedTable(props) {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [filtered, setFiltered] = React.useState([]);
    const [filterApplied, setFilterApplied] = React.useState(false);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    });

    const filterAllHandler = (event) => {
        if (event.target.value === '') {
            setFiltered([]);
            setFilterApplied(false);
        }
        else {
            let newRows = props.rows.filter(x => {
                let flag = false;
                if (selected.includes(x.id)) {
                    flag = true;
                }
                else {
                    Object.keys(x).map(value => {
                        let valueString = x[value];
                        if (value !== 'id' && valueString && valueString && valueString.toString().toUpperCase().includes(event.target.value.toUpperCase())) {
                            flag = true;
                        }
                    })
                }
                if (flag) {
                    return x;
                }
            })
            setFiltered(newRows);
            setFilterApplied(true);
            setPage(0);
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = event => {
        if (event.target.checked) {
            const newSelecteds = props.rows.map(n => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = name => selected.indexOf(name) !== -1;

    const handleDelete = (item) => {
        let newSelected = selected.filter(x => x !== item)
        setSelected(newSelected)
    }

    return (
        <div className={classes.root}>
            <div >Caută: <Input style={{ width: 250 }} onChange={filterAllHandler} /></div>
            <EnhancedTableToolbar handlePrint={handlePrint} addProductInList={props.addProductInList} headCells={props.headCells} currentUser={props.currentUser} updateProductInList={props.updateProductInList} updateProductAfterDelete={props.updateProductAfterDelete} numSelected={selected.length} deleteSelected={() => { setSelected([]) }} rows={props.rows.filter(row => selected.includes(row.id))} />
            {
                props.rows && props.rows.filter(row => selected.includes(row.id)).length > 0 ?
                    props.rows.filter(row => selected.includes(row.id)).map(x => <Chip
                        label={(x.specie ? x.specie : "") + " " + (x.soi ? x.soi : "")}
                        onDelete={() => handleDelete(x.id)}
                        style={{
                            backgroundColor: green[600], color: "white", margin: 8, borderRadius: '0.75rem',
                            boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)'
                        }}
                        deleteIcon={<CloseIcon style={{ color: "white" }} />}
                        size="small"
                    />) : null
            }
            <TableContainer style={{
                boxShadow: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)', padding: 1
            }}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={dense ? 'medium' : 'small'}
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        headCells={props.headCells}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={props.rows.length}
                    />
                    <TableBody>
                        {stableSort(filterApplied ? filtered : props.rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        style={{ color: green[600] }}
                                        hover
                                        onClick={event => handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <TableCell padding="checkbox" style={{ border: '1px solid #e2e2e2' }}>
                                            <Checkbox
                                                style={isItemSelected ? { color: green[600] } : {}}
                                                checked={isItemSelected}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </TableCell>
                                        {
                                            props.headCells.map(headCell => {
                                                if (headCell.id === 'data') {
                                                    let date;
                                                    try {
                                                        date = row[headCell.id].split('T')[0];
                                                    }
                                                    catch (err) {
                                                        date = row[headCell.id];
                                                    }
                                                    return <TableCell align="left" style={{ padding: 2, fontSize: 11, border: '1px solid #e2e2e2' }}>{date}</TableCell>
                                                }
                                                return <TableCell align="left" style={{ padding: 2, fontSize: 11, border: '1px solid #e2e2e2' }}>{row[headCell.id]}</TableCell>
                                            })
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={props.rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            <div style={{display: "none"}}>
            <div ref={componentRef} style={{padding: 40}}>
            <div id="templatePDFheader" style={{ display: 'none' }}>
                <table>
                    <thead>
                        <tr>
                            <td style={{ border: 'none' }}>ROMANIA</td>
                            <td rowSpan='5' style={{ border: 'none', marginLeft: '400px' }}>
                                {/*   <img src="" alt="cert"></img> */}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none' }}>FURNIZOR {props.currentUser.officialName}</td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none' }}>INREGISTRAT NR. {props.currentUser.nrInregistrare}</td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none', fontSize: 9 }}>Nr. ord. reg. com. {props.currentUser.numarRegistru}, C.I.F {props.currentUser.cif}</td>
                        </tr>
                        <tr>
                            <td style={{ border: 'none', fontSize: 9 }}>Sediu: {props.currentUser.sediu}</td>
                        </tr>
                    </thead>
                </table>
                <div className="text-centered">
                    <h5>DOCUMENT DE CALITATE SI CONFORMITATE AL FURNIZORULUI</h5>
                    <h6>Nr. &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; Data </h6>
                    <h6>Beneficiar: </h6>
                </div>
            </div>

            <div id="tableHere" className="text-centered" style={{ display: 'none', width: '100%' }}>

            </div>

            <div className="text-centered" id="templatePDFfooter" style={{ display: 'none' }}>
                <div>
                    <br></br>
                    <ul>
                        <li style={{ fontSize: '12px' }}>Samanta tratata cu *vezi ambalaj*</li>
                        <li style={{ fontSize: '12px' }}>Terment de valabilitate(garantie) *vezi ambalaj*</li>
                        <li style={{ fontSize: '12px' }}>Samanta se livreaza in conditiile art. 13 Legea 266/2002, republicata.</li>
                        <li style={{ fontSize: '12px' }}>Samanta corespunde pentru insamantare conform regulilor si standardelor Ordin MADAR nr. </li>
                        <li style={{ fontSize: '12px' }}>Locul de depozitare-livrare SEDIU: </li>
                    </ul>
                </div>
                <br></br>
                <p>Semnatura: </p>
            </div>
        </div>
        </div>
        </div>
    );
}