import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {Link, Redirect} from 'react-router-dom';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import LinkMaterial from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {green} from '@material-ui/core/colors';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
    {'Copyright © '}
    <LinkMaterial color="inherit" href="https://miculaprozar.ro">
      Micul Aprozar
    </LinkMaterial>{' '}
    office@miculaprozar.ro{' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: green[600],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [successSignUp, setSuccessSignUp] = React.useState(false);
  const [failedSingUp, setFailedSignUp] = React.useState(false);
  const [failedWithMessage, setFailedWithMessage] = React.useState('');

  const handlerSignUp = () => {
    let data = {
      firstName: document.getElementById('firstName').value,
      lastName: document.getElementById('lastName').value,
      email: document.getElementById('email').value,
      password: document.getElementById('password').value,
      company: document.getElementById('companyName').value
  }
  axios.post(`${process.env.REACT_APP_URL}/user/signUp`, data,{
      headers: {'Access-Control-Allow-Origin': '*'}})
          .then(res => {
            setSuccessSignUp(true);
          })
          .catch(err => {
            if(err.response && err.response.status === 400){
              let stringError  = '';
              err.response.data.errors.map(x => {
                stringError += `${x.msg};
                `
              })
              setFailedWithMessage(stringError);
            }
            else{
              setFailedSignUp(true);
            }
          })
  }

  const handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13) {
    handlerSignUp()
  }
}

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Creează cont
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                onClick={() => {setFailedSignUp(false); setSuccessSignUp(false); setFailedWithMessage('');}}
                id="firstName"
                label="Prenume"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                onClick={() => {setFailedSignUp(false); setSuccessSignUp(false); setFailedWithMessage('');}}
                label="Nume"
                name="Nume"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                id="companyName"
                onClick={() => {setFailedSignUp(false); setSuccessSignUp(false); setFailedWithMessage('');}}
                label="Nume Firmă"
                name="Nume Firmă"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Adresă Email"
                onClick={() => {setFailedSignUp(false); setSuccessSignUp(false); setFailedWithMessage('');}}
                name="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onKeyUp={handleKeypress}
                name="password"
                label="Parolă (minim 6 caractere)"
                onClick={() => {setFailedSignUp(false); setSuccessSignUp(false); setFailedWithMessage('');}}
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Grid>
          </Grid>
          {successSignUp ? <Redirect from="/signUp" to="/logIn"></Redirect> : null}
          {
            failedSingUp ? <div style={{marginTop: 20, color: "red"}}><b>Inregistrare cont nereusita!</b></div> : null
          }
          {
            failedWithMessage !== '' ? <div style={{marginTop: 20, color: "red"}}><b>{failedWithMessage}</b></div> : null
          }
          <Button
            fullWidth
            variant="contained"
            style={{backgroundColor: green[700], color: "white", boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)"}}
            className={classes.submit}
            onClick={handlerSignUp}
          >
            Creează cont
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to="/logIn" variant="body2">
                Deja ai un cont? Autentifică-te
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}