import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import axios from 'axios';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/Star';
import AccountIcon from '@material-ui/icons/AccountCircle';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import Toolbar from '@material-ui/core/Toolbar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import LinkMaterial from '@material-ui/core/Link';
import { Link, Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ReactPlayer from 'react-player';
import Box from '@material-ui/core/Box';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { green, grey, yellow, indigo } from '@material-ui/core/colors';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import image from '../assets/sigla.png';
import banner from '../assets/generator.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <LinkMaterial color="inherit" href="https://miculaprozar.ro">
        Micul Aprozar
    </LinkMaterial>{' '}
    office@miculaprozar.ro{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(4, 0, 1),
  },
  card: {
    boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)",
    borderRadius: "0.75rem"
  },
  cardHeader: {
    backgroundColor: grey[500],
    color: "white",
    boxShadow: "0 1px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 6%)"
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(0.5),
  },
  cardPricingPerYear: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
  },
  cardPricingDiscount: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  }
}));

let tryItButton = {
  initial: { textAlign: 'center',
    verticalAlign: 'middle', border: "1px solid #404040", marginTop: 40, backgroundColor: "#00bd51", fontSize: 16, color: "white", borderRadius: "0.75rem", boxShadow: "0 1px 15px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%)"},
  hover: { textAlign: 'center',
  verticalAlign: 'middle', border: "1px solid #404040", padding: 10, transition: "0.1s", marginTop: 40, backgroundColor: "#00b34d", fontSize: 18, color: "white", borderRadius: "0.75rem", boxShadow: "0 1px 20px rgb(0 0 0 / 20%), 0 1px 10px rgb(0 0 0 / 20%)"},
}

const tiers = [
  {
    title: 'Basic',
    price: '149',
    priceYear: '1599',
    description: ['<500 produse', 'Support tehnic', 'Certificat completat automat cu datele companiei']
  },
  {
    title: 'Pro',
    price: '299',
    priceYear: '3199',
    description: ['<1000 produse', 'Support tehnic', 'Certificat completat automat cu datele companiei']
  },
  {
    title: 'Enterprise',
    price: '499',
    priceYear: '5299',
    description: ['<3000 produse', 'Support tehnic', 'Certificat completat automat cu datele companiei']
  },
];

export default function Pricing() {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);
  const [hoverOnButton, setHoverOnButton] = React.useState(false);
  const matches = useMediaQuery('(max-width:430px)');

  useEffect(() => {
    let isCancelled = false;
    axios.get(`${process.env.REACT_APP_URL}/user`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': window.localStorage.getItem('token')
      }
    })
      .then(res => {
        setRedirect(true);
      })
      .catch(err => {
      })
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      {redirect ? <Redirect from="/pachete" to="/acasa"></Redirect> : null}
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
            {/* <img src={image} style={{ maxWidth: 240, maxHeight: 45 }}></img> */}
            {
              matches ? <img src={image} style={{ maxWidth: 240, maxHeight: 60 }}></img> : <div></div>
            }
           
            {/*  <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
            Generator Certificate
          </Typography> */}
            <Link to="/logIn" variant="body2" style={{ textDecoration: 'none' }}>
              <Button size="small" style={{ backgroundColor: green[600], color: "white", boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)", borderRadius: "0.75rem", marginTop: 5 }}>
                <AccountIcon style={{ marginRight: 4 }}></AccountIcon>{matches ? "" : "Intră în cont"}
              </Button>
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      {
        matches ? null : <img src={banner} style={{ maxWidth: "100%", maxHeight: 400 }}></img>
      }
      
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="generator-certificate-cookies"
          style={{
            background: '#454D51',
            fontSize: '14px',
            color: 'white',
            minHeight: '40px',
            paddingRight: '100px'
          }}
          buttonStyle={{
            background: '#39A36F',
            color: 'white',
            fontSize: '16px',
            borderRadius: '0.75rem'
          }}
          expires={150}
        >
          Folosim cookies pentru a îmbunătății continuu experența de utilizare.
          Apăsând Accept sau navigând pe acest website, ești de acord cu politica
          noastră de cookies.
        {/* <a
            href="/termeni-si-conditii"
            target="_blank"
            style={{ color: '#39a36f' }}
          >
            {' Politica de Cookies'}
          </a> */}
        </CookieConsent>
        {/* <img src={image} style={{ marginBottom: 10, maxWidth: "100%" }}></img> */}
        {/* <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Generator Certificate
        </Typography> */}
        <Typography variant="h6" align="center" color="textSecondary" component="p">
          Destinat facilitării procesului de generare a certificatelor de calitate de tip APIA din magazinele fitosanitare pentru producători.
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary" component="p" style={{fontSize: 16}}>
          Detalii prin email la <span style={{color: green[700]}}><b>office@miculaprozar.ro</b></span> sau telefon la <span style={{color: green[700]}}><b>0774043791</b></span>
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={4} alignItems="flex-end">
          <Grid item xs={12}>
          <Link to="/signUp" variant="body2" style={{ textDecoration: 'none' }}>
              <div onMouseEnter={() => {setHoverOnButton(true)}} onMouseLeave={() => {setHoverOnButton(false)}}  style={ hoverOnButton ? tryItButton.hover : tryItButton.initial} variant='contained'>
                <TouchAppIcon style={{fontSize: 32, whiteSpace: "pre-wrap"}} ></TouchAppIcon> <span style={{marginRight: 8, marginLeft: 8, fontSize: 24}}><b>ÎNCEARCĂ</b></span> <span style={{marginRight: 8, fontSize: 32}}><b>GRATUIT</b></span> <span style={{fontSize: 22}}>timp de 30 de zile</span>
                </div>
            </Link>
          </Grid>
          <Grid item xs={12} style={{ borderTop: "2px solid grey", marginTop: 40}}>
            <Grid container spacing={4}>
            <Grid item xs={12} style={{marginTop: 30}}>
          <Typography variant="h5" align="left" color="textSecondary" component="h5"><b>Tipuri de pachete disponibile după perioada de gratuitate</b></Typography>
          </Grid>
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card className={classes.card}>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon style={{color: yellow[600]}} /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>
                  <div className={classes.cardPricing}>
                    <Typography component="h2" variant="h3" color="textPrimary">
                      <i style={{ fontSize: 20 }}>RON{" "}</i>{tier.price}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /lună
                    </Typography>
                  </div>
                  <div className={classes.cardPricingPerYear}>
                    <Typography component="h6" variant="h6" color="textPrimary">
                      <i style={{ fontSize: 18 }}>* RON{" "}</i>{tier.priceYear}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /an
                    </Typography>
                  </div>
                  <div className={classes.cardPricingDiscount}>
                    <Typography component="h6" variant="h6" color="textPrimary">
                      <i style={{ fontSize: 14, color: "green" }}>* 10% reducere</i>
                    </Typography>
                  </div>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
            </Grid>
          </Grid>
          
        </Grid>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Typography variant="body2" align="center" color="textSecondary" component="p">
              Necesită Microsoft Office Word instalat.
        </Typography>
          </Grid>
        </Grid>

      </Container>
      {/* Footer */}
      <Container maxWidth="xl" component="footer" className={classes.footer}>
        <Grid container spacing={4} justify="space-evenly">
          <ReactPlayer url='https://storage.googleapis.com/production-photos/generator.mp4' style={{ boxShadow: "0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)" }} controls={true} volume={0} muted={true} playing={true} loop={true} />
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}