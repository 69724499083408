import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Redirect,
  Route
} from "react-router-dom";
import './index.css';
import App from './App';
import LogIn from './pages/LogIn';
import SignUp from './pages/SignUp';
import WelcomePage from './pages/WelcomePage';
import TermsAndConditions from './pages/TermsAndConditions';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
  <Router>
   <Route exact path="/" component={WelcomePage} />
   <Route path="/pachete" component={WelcomePage} />
  {/*  <Route path="/termeni-si-conditii" component={TermsAndConditions} /> */}
   <Route path="/acasa" component={App} />
   <Route path="/logIn" component={LogIn} />
   <Route path="/signUp" component={SignUp} />
  </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
